import axios, { AxiosError } from "axios"
import { AnyAction } from "redux"
import { v4 as uuidv4 } from "uuid"

import { Dispatch, RNBThunkAction } from "../store.config"
import {
  changeBuyOrSell,
  changeDocumentType,
  createWritingLine,
  DeactivatedDocumentInWritingForInputMask,
  decreaseCents,
  DocumentType,
  FullDocumentForInputMask,
  generateEmptyTaxIncluded,
  increaseCents,
  isFullDocumentDeactivated,
  isMagicCheckBoxDatumType,
  LineToAdd,
  MagicCheckBoxDatumTypes,
  UnitValues,
  updateAmount,
  updateTaxIncluded,
  VatAccountsWithRate,
  VatRate,
  WritingLine,
  WritingLinePayload,
} from "../../utils/inputMask"
import {
  DocumentSource,
  FullDocumentsActionsEnum,
  GoodsOrServices,
  fullDocumentUpdateSuccess,
} from "./fullDocuments.ducks"
import { DeactivatedSettingsKey, GetCompanyThunk } from "./companies.ducks"
import { copyObject } from "../../utils/object"
import {
  FullDocumentModification,
  getFullDocumentModification,
  getWritingLineChanges,
  WritingLineChange,
  WritingLineModification,
} from "../../utils/inputMaskHistory"
import {
  BanLockedAccountFailure,
  DeactivatedAccountFailure,
  EditMerchantNameFailure,
  InstructionType,
  LockBannedAccountFailure,
  MerchantInstructionPayload,
} from "./merchants.ducks"
import { buyOrSell } from "../../utils/company"
import { RootState } from "../rootReducer"
import {
  CreateMerchantCodeFromInputMask,
  CreateOrUpdateMerchant,
  GetAllMerchantsOfCompanyForCodes,
  GetCompanyMerchantCodesDataThunk,
  MerchantCode,
  MerchantsOfCompany,
  ToastErrorProps,
  addMerchantCode,
  displayErrorMerchantCodes,
} from "./merchantCodes.ducks"
import { Account } from "./accounts.ducks"

export const enum InputMaskActionsEnum {
  GET_INPUT_MASK_WRITING_ATTEMPT = "INPUT_MASK/getInputMaskWritingAttempt",
  GET_INPUT_MASK_WRITING_SUCCESS = "INPUT_MASK/getInputMaskWritingSuccess",
  GET_INPUT_MASK_WRITING_ERROR = "INPUT_MASK/getInputMaskWritingErrror",

  GET_INPUT_MASK_MERCHANT_ATTEMPT = "INPUT_MASK/getInputMaskMerchantAttempt",
  GET_INPUT_MASK_MERCHANT_SUCCESS = "INPUT_MASK/getInputMaskMerchantSuccess",
  GET_INPUT_MASK_MERCHANT_ERROR = "INPUT_MASK/getInputMaskMerchantErrror",

  UPDATE_WRITING_LINES_ATTEMPT = "INPUT_MASK/updateWritingLinesAttempt",
  UPDATE_WRITING_LINES_SUCCESS = "INPUT_MASK/updateWritingLinesSuccess",
  UPDATE_WRITING_LINES_ERROR = "INPUT_MASK/updateWritingLinesErrror",

  GET_INPUT_MASK_ACCOUNTS_ATTEMPT = "INPUT_MASK/getInputMaskAccountsAttempt",
  GET_INPUT_MASK_ACCOUNTS_SUCCESS = "INPUT_MASK/getInputMaskAccountsSuccess",
  GET_INPUT_MASK_ACCOUNTS_ERROR = "INPUT_MASK/getInputMaskAccountsErrror",

  SELECT_NEW_ACCOUNT = "INPUT_MASK/selectNewAccount",

  CREATE_MERCHANT_ATTEMPT = "INPUT_MASK/createMerchantAttempt",
  CREATE_MERCHANT_SUCCESS = "INPUT_MASK/createMerchantSuccess",
  CREATE_MERCHANT_RESET = "INPUT_MASK/createMerchantReset",
  CREATE_MERCHANT_ERROR = "INPUT_MASK/createMerchantErrror",

  GET_HISTORY_ATTEMPT = "INPUT_MASK/getHistoryAttempt",
  GET_HISTORY_SUCCESS = "INPUT_MASK/getHistorySuccess",
  GET_HISTORY_ERROR = "INPUT_MASK/getHistoryErrror",

  GET_MERCHANT_IMPACTED_DOCUMENTS_ATTEMPT = "INPUT_MASK/getMerchantImpactedDocumentsAttempt",
  GET_MERCHANT_IMPACTED_DOCUMENTS_SUCCESS = "INPUT_MASK/getMerchantImpactedDocumentsSuccess",
  GET_MERCHANT_IMPACTED_DOCUMENTS_ERROR = "INPUT_MASK/getMerchantImpactedDocumentsErrror",

  GET_IDENTIFICATION_NUMBERS_ATTEMPT = "INPUT_MASK/getIdentificationNumbersAttempt",
  GET_IDENTIFICATION_NUMBERS_SUCCESS = "INPUT_MASK/getIdentificationNumbersSuccess",
  GET_IDENTIFICATION_NUMBERS_ERROR = "INPUT_MASK/getIdentificationNumbersErrror",

  CREATE_MERCHANT_CODE_ATTEMPT = "INPUT_MASK/createMerchantCodeAttempt",
  CREATE_MERCHANT_CODE_SUCCESS = "INPUT_MASK/createMerchantCodeSuccess",
  CREATE_MERCHANT_CODE_ERROR = "INPUT_MASK/createMerchantCodeErrror",
  CREATE_MERCHANT_CODE_RESET = "INPUT_MASK/createMerchantCodeReset",

  UPDATE_MERCHANT_CODE_CENTRALIZE_ATTEMPT = "INPUT_MASK/UpdateMerchantCodeCentralizeAttempt",
  UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS = "INPUT_MASK/UpdateMerchantCodeCentralizeSuccess",
  UPDATE_MERCHANT_CODE_CENTRALIZE_FAILURE = "INPUT_MASK/UpdateMerchantCodeCentralizeFailure",

  TOGGLE_BLOCKED_BY_ALREADY_TAKEN_MERCHANT_CODE = "INPUT_MASK/toggleBlockedByAlreadyTakenMerchantCode",

  UPDATE_FULL_DOCUMENT_REVIEW = "INPUT_MASK/update_full_document_review",
  RESET_REVIEW = "INPUT_MASK/reset_review",
  UPDATE_AMOUNT = "INPUT_MASK/update_amount",
  TOGGLE_IS_FUEL_VAT = "INPUT_MASK/toggle_is_fuel_vat",
  UNIT_VALUES = "INPUT_MASK/unit_values",
  CREATE_LINE = "INPUT_MASK/create_line",
  DESTROY_LINE = "INPUT_MASK/destroy_line",
  INCREASE_CENTS = "INPUT_MASK/increase_cents",
  DECREASE_CENTS = "INPUT_MASK/decrease_cents",
  CHANGE_BILL_OF_EXCHANGE_MERCHANT = "INPUT_MASK/changeBillOfExchangeMerchant",
  CHANGE_ACCOUNT = "INPUT_MASK/change_account",
  CHANGE_MERCHANT = "INPUT_MASK/change_merchant",
  CHANGE_MERCHANT_CODE = "INPUT_MASK/change_merchant_code",
  CHANGE_TYPOLOGY = "INPUT_MASK/change_typology",
  FOCUS_LINE = "INPUT_MASK/focus_line",
  SELECT_TAB = "INPUT_MASK/select_tab",
  FREE_TEXT_FULL_DOC = "INPUT_MASK/free_text_full_doc",
  SET_SELECTED_FULL_DOCUMENT = "INPUT_MASK/set_selected_full_document",
  SET_SELECTED_PAGE = "INPUT_MASK/set_selected_page",
  STORE_CONTEXT = "INPUT_MASK/store_context",
  EXPAND_ALL_INVOICES = "INPUT_MASK/expand_all_invoices",
  MINIMIZE_ALL_INVOICES = "INPUT_MASK/minimize_all_invoices",

  SET_MERCHANT_INSTRUCTION = "INPUT_MASK/set_merchant_instruction",
  MERCHANT_INSTRUCTION_SUCCESS = "INPUT_MASK/merchant_instruction_success",
  MERCHANT_INSTRUCTION_ERROR = "INPUT_MASK/merchant_instruction_error",

  DEACTIVATE_FULL_DOCUMENT_ATTEMPT = "INPUT_MASK/deactivateFullDocumentAttempt",
  DEACTIVATE_FULL_DOCUMENT_SUCCESS = "INPUT_MASK/deactivateFullDocumentSuccess",
  DEACTIVATE_FULL_DOCUMENT_RESET = "INPUT_MASK/deactivateFullDocumentReset",
  DEACTIVATE_FULL_DOCUMENT_ERROR = "INPUT_MASK/deactivateFullDocumentErrror",

  REACTIVATE_DOCUMENT = "INPUT_MASK/reactivate_document",
  CANCEL_DOCUMENT_REACTIVATION = "INPUT_MASK/cancel_document_reactivation",

  RESET_ACCOUNT_TO_SELECT = "INPUT_MASK/reset_account_to_select",
}

export const getInputMaskWritingAttemptAction = () =>
  ({ type: InputMaskActionsEnum.GET_INPUT_MASK_WRITING_ATTEMPT } as const)
export const getInputMaskWritingSuccessAction = (
  payload: InputMaskWritingResponse
) =>
  ({
    type: InputMaskActionsEnum.GET_INPUT_MASK_WRITING_SUCCESS,
    payload,
  } as const)
export const getInputMaskWritingErrorAction = (e: AxiosError) =>
  ({ type: InputMaskActionsEnum.GET_INPUT_MASK_WRITING_ERROR } as const)

export const getInputMaskAccountsAttemptAction = () =>
  ({ type: InputMaskActionsEnum.GET_INPUT_MASK_ACCOUNTS_ATTEMPT } as const)
export const getInputMaskAccountsSuccessAction = (
  payload: InputMaskAccountsPayload
) =>
  ({
    type: InputMaskActionsEnum.GET_INPUT_MASK_ACCOUNTS_SUCCESS,
    payload,
  } as const)

export const selectNewAccountAction = (payload: {
  buyOrsell: buyOrSell
  newAccount: Account
  writingLineUuid: string | undefined
}) =>
  ({
    type: InputMaskActionsEnum.SELECT_NEW_ACCOUNT,
    payload,
  } as const)

export const getInputMaskAccountsErrorAction = (e: AxiosError) =>
  ({ type: InputMaskActionsEnum.GET_INPUT_MASK_ACCOUNTS_ERROR } as const)

export const createMerchantAttemptAction = () =>
  ({ type: InputMaskActionsEnum.CREATE_MERCHANT_ATTEMPT } as const)

export const createMerchantResetAction = () =>
  ({ type: InputMaskActionsEnum.CREATE_MERCHANT_RESET } as const)
export const createMerchantSuccessAction = (merchant: CreateMerchantResponse) =>
  ({
    type: InputMaskActionsEnum.CREATE_MERCHANT_SUCCESS,
    payload: merchant,
  } as const)
export const createMerchantErrorAction = (e: AxiosError<{ error: string }>) => {
  if (
    e.response &&
    JSON.stringify(e.response.data).includes("Code is too long")
  ) {
    return {
      type: InputMaskActionsEnum.CREATE_MERCHANT_ERROR,
      withToast: true,
      toasterType: "error",
      message: {
        titleKey: "input-mask.toaster.error.merchant-code-creation.title",
        bodyKey:
          "input-mask.toaster.error.merchant-code-creation.body.too-long",
      },
    } as const
  }
  if (
    e.response &&
    JSON.stringify(e.response.data).includes("Ce code est déjà utilisé par le")
  ) {
    const message = e.response.data.error.replace(
      "Impossible de sauvegarder : ",
      ""
    )
    return {
      type: InputMaskActionsEnum.CREATE_MERCHANT_ERROR,
      withToast: true,
      toasterType: "error",
      message: {
        titleKey: "input-mask.toaster.error.merchant-creation.title",
        bodyKey: message,
      },
    } as const
  }
  return {
    type: InputMaskActionsEnum.CREATE_MERCHANT_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "input-mask.toaster.error.merchant-creation.title",
      bodyKey: "input-mask.toaster.error.merchant-creation.body",
    },
  } as const
}

export const getInputMaskMerchantAttemptAction = () =>
  ({ type: InputMaskActionsEnum.GET_INPUT_MASK_MERCHANT_ATTEMPT } as const)
export const getInputMaskMerchantSuccessAction = (
  payload: InputMaskMerchantResponse
) =>
  ({
    type: InputMaskActionsEnum.GET_INPUT_MASK_MERCHANT_SUCCESS,
    payload,
  } as const)
export const getInputMaskMerchantErrorAction = (e: AxiosError) =>
  ({ type: InputMaskActionsEnum.GET_INPUT_MASK_MERCHANT_ERROR } as const)

export const getHistoryAttemptAction = () =>
  ({ type: InputMaskActionsEnum.GET_HISTORY_ATTEMPT } as const)
export const getHistorySuccessAction = (payload: {
  history_modifications: HistoryModification[]
}) =>
  ({
    type: InputMaskActionsEnum.GET_HISTORY_SUCCESS,
    payload,
  } as const)
export const getHistoryErrorAction = (e: AxiosError) =>
  ({ type: InputMaskActionsEnum.GET_HISTORY_ERROR } as const)

export const getMerchantImpactedDocumentsAttemptAction = () =>
  ({
    type: InputMaskActionsEnum.GET_MERCHANT_IMPACTED_DOCUMENTS_ATTEMPT,
  } as const)
export const getMerchantImpactedDocumentsSuccessAction = (payload: {
  impacted_documents: number
}) =>
  ({
    type: InputMaskActionsEnum.GET_MERCHANT_IMPACTED_DOCUMENTS_SUCCESS,
    payload,
  } as const)
export const getMerchantImpactedDocumentsErrorAction = (e: AxiosError) =>
  ({
    type: InputMaskActionsEnum.GET_MERCHANT_IMPACTED_DOCUMENTS_ERROR,
  } as const)

export const getIdentificationNumbersAttemptAction = () =>
  ({
    type: InputMaskActionsEnum.GET_IDENTIFICATION_NUMBERS_ATTEMPT,
  } as const)

export const getIdentificationNumbersSuccessAction = (
  payload: FullDocumentIdentificationNumber[]
) =>
  ({
    type: InputMaskActionsEnum.GET_IDENTIFICATION_NUMBERS_SUCCESS,
    payload,
  } as const)
export const getIdentificationNumbersErrorAction = (e: AxiosError) =>
  ({
    type: InputMaskActionsEnum.GET_IDENTIFICATION_NUMBERS_ERROR,
  } as const)

export const createMerchantCodeResetAction = () =>
  ({
    type: InputMaskActionsEnum.CREATE_MERCHANT_CODE_RESET,
  } as const)
export const createMerchantCodeAttemptAction = () =>
  ({
    type: InputMaskActionsEnum.CREATE_MERCHANT_CODE_ATTEMPT,
  } as const)
export const createMerchantCodeSuccessAction = (payload: {
  merchant_code_id: number
}) =>
  ({
    type: InputMaskActionsEnum.CREATE_MERCHANT_CODE_SUCCESS,
    payload,
  } as const)
export const createMerchantCodeErrorAction = (error: ToastErrorProps) =>
  ({
    type: InputMaskActionsEnum.CREATE_MERCHANT_CODE_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.titleKey,
      bodyKey: error.bodyKey,
      bodyValues: error.bodyValues,
    },
  } as const)

export const updateWritingLinesErrorAction = ({
  titleKey,
  bodyKey,
}: {
  titleKey: string
  bodyKey: string
}) =>
  ({
    type: InputMaskActionsEnum.UPDATE_WRITING_LINES_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey,
      bodyKey,
    },
  } as const)

export const updateWritingLinesAttemptAction = () =>
  ({
    type: InputMaskActionsEnum.UPDATE_WRITING_LINES_ATTEMPT,
  } as const)
export const updateWritingLinesSuccessAction = ({
  editedWritingLines,
  editedFullDocument,
  fullDocumentId,
  merchantCodeId,
  merchantCode,
}: {
  fullDocumentId: number
  merchantCodeId: number
  merchantCode: string
  editedWritingLines: WritingLinePayload[]
  editedFullDocument: FullDocumentOnly
}) =>
  ({
    type: InputMaskActionsEnum.UPDATE_WRITING_LINES_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "input-mask.toaster.success.title",
      bodyKey: "input-mask.toaster.success.body",
    },
    payload: {
      editedWritingLines,
      editedFullDocument,
      fullDocumentId,
      merchantCodeId,
      merchantCode,
    },
  } as const)

export const updateFullDocumentReview = (payload: FullDocumentOnly) =>
  ({
    type: InputMaskActionsEnum.UPDATE_FULL_DOCUMENT_REVIEW,
    payload,
  } as const)
export const resetReview = (payload: {
  initialFullDocument: FullDocumentOnly
  initialWritinglines: WritingLinePayload[]
}) =>
  ({
    type: InputMaskActionsEnum.RESET_REVIEW,
    payload,
  } as const)

export const updateAmountAction = (payload: {
  newAmount: string
  writingLine: WritingLinePayload
  newCurrencyAmount: string
}) =>
  ({
    type: InputMaskActionsEnum.UPDATE_AMOUNT,
    payload,
  } as const)

export const toggleIsFuelVatAction = (payload: { writingLineUuid: string }) =>
  ({
    type: InputMaskActionsEnum.TOGGLE_IS_FUEL_VAT,
    payload,
  } as const)

export const unitValuesAction = (payload: {
  writingLineUuid: string
  unit_values: UnitValues
}) =>
  ({
    type: InputMaskActionsEnum.UNIT_VALUES,
    payload,
  } as const)

export const createLineAction = (payload: {
  selectedOption: LineToAdd
  possibleAccounts: AllAccounts
  buyThirdPartyAccount: Account | null
}) =>
  ({
    type: InputMaskActionsEnum.CREATE_LINE,
    payload,
  } as const)

export const destroyLineAction = (payload: { writingLineUuid: string }) =>
  ({
    type: InputMaskActionsEnum.DESTROY_LINE,
    payload,
  } as const)

export const increaseCentsAction = (payload: {
  writingLine: WritingLinePayload
}) =>
  ({
    type: InputMaskActionsEnum.INCREASE_CENTS,
    payload,
  } as const)

export const decreaseCentsAction = (payload: {
  writingLine: WritingLinePayload
}) =>
  ({
    type: InputMaskActionsEnum.DECREASE_CENTS,
    payload,
  } as const)

export const changeAccount = (payload: {
  writingLine: WritingLinePayload
  newAccount: Account
}) =>
  ({
    type: InputMaskActionsEnum.CHANGE_ACCOUNT,
    payload,
  } as const)

export const changeBillOfExchangeMerchant = (payload: {
  writingLine: WritingLinePayload
  newMerchantCodeId: number
}) =>
  ({
    type: InputMaskActionsEnum.CHANGE_BILL_OF_EXCHANGE_MERCHANT,
    payload,
  } as const)

export const changeMerchant = (payload: {
  merchantId: number
  merchantName: string
  merchantCode: string | null
  merchantCodeId: number | null
}) =>
  ({
    type: InputMaskActionsEnum.CHANGE_MERCHANT,
    payload,
  } as const)

export const changeMerchantCode = (payload: { merchantCodeId: number }) =>
  ({
    type: InputMaskActionsEnum.CHANGE_MERCHANT_CODE,
    payload,
  } as const)

export const changeTypology = (payload: {
  documentType: DocumentType
  buyOrSell: buyOrSell
}) =>
  ({
    type: InputMaskActionsEnum.CHANGE_TYPOLOGY,
    payload,
  } as const)

export const focusLine = () =>
  ({
    type: InputMaskActionsEnum.FOCUS_LINE,
  } as const)

export const expandAllInvoices = () =>
  ({
    type: InputMaskActionsEnum.EXPAND_ALL_INVOICES,
  } as const)

export const minimizeAllInvoices = () =>
  ({
    type: InputMaskActionsEnum.MINIMIZE_ALL_INVOICES,
  } as const)

export const selectTab = (selectedTab: "processed" | "deactivated") =>
  ({
    type: InputMaskActionsEnum.SELECT_TAB,
    payload: selectedTab,
  } as const)

export const freeTextFullDocAction = (free_text_full_doc: string) =>
  ({
    type: InputMaskActionsEnum.FREE_TEXT_FULL_DOC,
    payload: free_text_full_doc,
  } as const)

export const setSelectedFullDocument = (
  selectedFullDocument:
    | FullDocumentForInputMask
    | DeactivatedDocumentInWritingForInputMask
    | null
) =>
  ({
    type: InputMaskActionsEnum.SET_SELECTED_FULL_DOCUMENT,
    payload: selectedFullDocument,
  } as const)

export const setSelectedPage = (selectedPage: number) =>
  ({
    type: InputMaskActionsEnum.SET_SELECTED_PAGE,
    payload: selectedPage,
  } as const)

export const storeContext = (payload: {
  companyId: number
  merchantId: number | null
  fiscalYearId: number
}) =>
  ({
    type: InputMaskActionsEnum.STORE_CONTEXT,
    payload,
  } as const)

export const setMerchantInstruction = (payload: {
  datum_type: MagicCheckBoxDatumTypes
  merchant_instruction: GiveMerchantInstructionPayload
}) =>
  ({
    type: InputMaskActionsEnum.SET_MERCHANT_INSTRUCTION,
    payload,
  } as const)

export const merchantInstructionSuccess = (payload: { buyOrSell: buyOrSell }) =>
  ({
    type: InputMaskActionsEnum.MERCHANT_INSTRUCTION_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `input-mask.modify-merchant.success.title`,
      bodyKey: `input-mask.modify-merchant.success.body-${payload.buyOrSell}`,
    },
  } as const)
export const merchantInstructionError = (payload: { buyOrSell: buyOrSell }) =>
  ({
    type: InputMaskActionsEnum.MERCHANT_INSTRUCTION_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `input-mask.modify-merchant.error.title`,
      bodyKey: `input-mask.modify-merchant.error.body-${payload.buyOrSell}`,
    },
  } as const)

export const deactivateFullDocumentAttemptAction = () =>
  ({ type: InputMaskActionsEnum.DEACTIVATE_FULL_DOCUMENT_ATTEMPT } as const)
export const deactivateFullDocumentSuccessAction = ({
  fullDocumentId,
}: {
  fullDocumentId: number
}) =>
  ({
    type: InputMaskActionsEnum.DEACTIVATE_FULL_DOCUMENT_SUCCESS,
    fullDocumentId,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `input-mask.deactivate-document.success.title`,
      bodyKey: `input-mask.deactivate-document.success.body`,
    },
  } as const)
export const deactivateFullDocumentResetAction = () =>
  ({ type: InputMaskActionsEnum.DEACTIVATE_FULL_DOCUMENT_RESET } as const)
export const deactivateFullDocumentErrorAction = (e: AxiosError) =>
  ({ type: InputMaskActionsEnum.DEACTIVATE_FULL_DOCUMENT_ERROR } as const)

export const reactivateDocumentAction = ({ fd_id }: { fd_id: number }) =>
  ({
    type: InputMaskActionsEnum.REACTIVATE_DOCUMENT,
    payload: { fd_id },
  } as const)

export const cancelReactivationAction = ({ fd_id }: { fd_id: number }) =>
  ({
    type: InputMaskActionsEnum.CANCEL_DOCUMENT_REACTIVATION,
    payload: { fd_id },
  } as const)

export const resetAccountToSelectAction = () =>
  ({ type: InputMaskActionsEnum.RESET_ACCOUNT_TO_SELECT } as const)

export const UpdateMerchantCodeCentralizeAttempt = () =>
  ({
    type: InputMaskActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_ATTEMPT,
  } as const)
export const UpdateMerchantCodeCentralizeSuccess = (payload: {
  merchantCodeId: number
}) =>
  ({
    type: InputMaskActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS,
    payload,
  } as const)
export const UpdateMerchantCodeCentralizeFailure = (
  error: AxiosError | Error
) =>
  ({
    type: InputMaskActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `${displayErrorMerchantCodes(error)}.title`,
      bodyKey: `${displayErrorMerchantCodes(error)}.message`,
    },
  } as const)

export const toggleBlockedByAlreadyTakenMerchantCode = ({
  blockedByAlreadyTakenMerchantCode,
}: {
  blockedByAlreadyTakenMerchantCode: boolean
}) =>
  ({
    type: InputMaskActionsEnum.TOGGLE_BLOCKED_BY_ALREADY_TAKEN_MERCHANT_CODE,
    payload: { blockedByAlreadyTakenMerchantCode },
  } as const)

type InputMaskActionsType = ReturnType<
  | typeof getInputMaskWritingAttemptAction
  | typeof getInputMaskWritingSuccessAction
  | typeof getInputMaskWritingErrorAction
  | typeof getInputMaskMerchantAttemptAction
  | typeof getInputMaskMerchantSuccessAction
  | typeof getInputMaskMerchantErrorAction
  | typeof getInputMaskAccountsAttemptAction
  | typeof getInputMaskAccountsSuccessAction
  | typeof getInputMaskAccountsErrorAction
  | typeof selectNewAccountAction
  | typeof createMerchantAttemptAction
  | typeof createMerchantResetAction
  | typeof createMerchantSuccessAction
  | typeof createMerchantErrorAction
  | typeof getHistoryAttemptAction
  | typeof getHistorySuccessAction
  | typeof getHistoryErrorAction
  | typeof getMerchantImpactedDocumentsAttemptAction
  | typeof getMerchantImpactedDocumentsSuccessAction
  | typeof getMerchantImpactedDocumentsErrorAction
  | typeof getIdentificationNumbersAttemptAction
  | typeof getIdentificationNumbersSuccessAction
  | typeof getIdentificationNumbersErrorAction
  | typeof updateWritingLinesAttemptAction
  | typeof updateWritingLinesSuccessAction
  | typeof updateWritingLinesErrorAction
  | typeof createMerchantCodeAttemptAction
  | typeof createMerchantCodeResetAction
  | typeof createMerchantCodeSuccessAction
  | typeof createMerchantCodeErrorAction
  | typeof updateFullDocumentReview
  | typeof resetReview
  | typeof updateAmountAction
  | typeof toggleIsFuelVatAction
  | typeof unitValuesAction
  | typeof createLineAction
  | typeof destroyLineAction
  | typeof fullDocumentUpdateSuccess
  | typeof increaseCentsAction
  | typeof decreaseCentsAction
  | typeof changeAccount
  | typeof changeBillOfExchangeMerchant
  | typeof changeMerchant
  | typeof changeMerchantCode
  | typeof changeTypology
  | typeof focusLine
  | typeof storeContext
  | typeof selectTab
  | typeof expandAllInvoices
  | typeof minimizeAllInvoices
  | typeof freeTextFullDocAction
  | typeof setSelectedFullDocument
  | typeof setSelectedPage
  | typeof setMerchantInstruction
  | typeof merchantInstructionSuccess
  | typeof merchantInstructionError
  | typeof deactivateFullDocumentAttemptAction
  | typeof deactivateFullDocumentSuccessAction
  | typeof deactivateFullDocumentResetAction
  | typeof deactivateFullDocumentErrorAction
  | typeof reactivateDocumentAction
  | typeof cancelReactivationAction
  | typeof resetAccountToSelectAction
  | typeof UpdateMerchantCodeCentralizeAttempt
  | typeof UpdateMerchantCodeCentralizeSuccess
  | typeof UpdateMerchantCodeCentralizeFailure
  | typeof toggleBlockedByAlreadyTakenMerchantCode
>

export type FullDocumentOnly = Omit<FullDocumentForInputMask, "writing_lines">

export type HistoryModification =
  | WritingLineModification
  | WritingLineChange
  | FullDocumentModification

interface CentsChange {
  writing_line_uuid: string
}

export interface InputMaskState {
  full_documents_to_check: FullDocumentForInputMask[]
  deactivated_full_documents: DeactivatedDocumentInWritingForInputMask[]
  initial_full_document: FullDocumentOnly | null
  edited_full_document: FullDocumentOnly | null
  initial_writing_lines: WritingLinePayload[]
  edited_writing_lines: WritingLinePayload[]
  all_company_accounts: AllCompanyAccounts | null
  vat_accounts: VatAccountsWithRate[]
  history_modifications: HistoryModification[]
  increased_cents: CentsChange[]
  decreased_cents: CentsChange[]
  magic_checkbox: {
    vat: {
      writing_line_uuid: string
      merchant_instruction: GiveMerchantInstructionPayload | null
    } | null
    tax_excluded: {
      writing_line_uuid: string
      merchant_instruction: GiveMerchantInstructionPayload | null
    } | null
  }
  impacted_documents: number
  loading_status: "loading" | "finished"
  deactivate_status: "idle" | "success"
  companyId: number | undefined
  merchantId: number | null | undefined
  fiscalYearId: number | undefined
  new_line_to_select: string | null
  selectedTab: "processed" | "deactivated"
  selectedFullDocument:
    | FullDocumentForInputMask
    | DeactivatedDocumentInWritingForInputMask
    | null
  selectedPage: number
  identificationNumbers: FullDocumentIdentificationNumber[]
  identificationNumbersLoadingStatus: "loading" | "finished"
  createMerchantStatus: "idle" | "loading" | "success" | "failure"
  createOrUpdateMerchantCodeStatus: "idle" | "loading" | "success" | "failure"
  updateWritinglinesStatus: "idle" | "loading" | "success" | "failure"
  accountIdToSelectFromCreation: {
    accountId: number
    writinLineUuid: string | undefined
  } | null
  autoliquidation_vat_rates: AutoliquidationVat
  blockedByAlreadyTakenMerchantCode: boolean
  canAddFreeTextFullDoc: boolean
  expandInvoicesList: "expanded" | "minimized"
}

export const InputMaskInitialState: InputMaskState = {
  full_documents_to_check: [],
  deactivated_full_documents: [],
  initial_full_document: null,
  edited_full_document: null,
  initial_writing_lines: [],
  edited_writing_lines: [],
  all_company_accounts: null,
  vat_accounts: [],
  history_modifications: [],
  increased_cents: [],
  decreased_cents: [],
  new_line_to_select: null,
  magic_checkbox: {
    vat: null,
    tax_excluded: null,
  },
  impacted_documents: 0,
  loading_status: "loading",
  deactivate_status: "idle",
  companyId: undefined,
  merchantId: undefined,
  fiscalYearId: undefined,
  selectedTab: "processed",
  selectedFullDocument: null,
  selectedPage: 0,
  identificationNumbers: [],
  identificationNumbersLoadingStatus: "loading",
  createMerchantStatus: "idle",
  createOrUpdateMerchantCodeStatus: "idle",
  updateWritinglinesStatus: "idle",
  accountIdToSelectFromCreation: null,
  autoliquidation_vat_rates: {
    eu_vat: 0,
    eu_goods_vat: 0,
    world_vat: 0,
    world_goods_vat: 0,
    construction_vat: 0,
  },
  blockedByAlreadyTakenMerchantCode: false,
  canAddFreeTextFullDoc: false,
  expandInvoicesList: "minimized",
}

export function InputMaskReducer(
  state = InputMaskInitialState,
  action: InputMaskActionsType
): InputMaskState {
  switch (action.type) {
    case InputMaskActionsEnum.GET_INPUT_MASK_WRITING_ATTEMPT:
      return InputMaskInitialState

    case InputMaskActionsEnum.GET_INPUT_MASK_WRITING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        canAddFreeTextFullDoc: action.payload.can_add_free_text_full_doc,
        loading_status: "finished",
        selectedPage: 0,
        selectedFullDocument: action.payload.full_documents_to_check[0],
      }

    case InputMaskActionsEnum.GET_INPUT_MASK_WRITING_ERROR:
      return {
        ...state,
        loading_status: "finished",
      }

    case InputMaskActionsEnum.GET_INPUT_MASK_MERCHANT_ATTEMPT:
      return InputMaskInitialState

    case InputMaskActionsEnum.GET_INPUT_MASK_MERCHANT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading_status: "finished",
        selectedPage: 0,
        selectedFullDocument: action.payload.full_documents_to_check[0],
      }
    case InputMaskActionsEnum.GET_INPUT_MASK_MERCHANT_ERROR:
      return {
        ...state,
        loading_status: "finished",
      }

    case InputMaskActionsEnum.GET_HISTORY_SUCCESS:
      return {
        ...state,
        history_modifications: action.payload.history_modifications,
      }

    case InputMaskActionsEnum.GET_MERCHANT_IMPACTED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        impacted_documents: action.payload.impacted_documents,
      }

    case InputMaskActionsEnum.GET_MERCHANT_IMPACTED_DOCUMENTS_ERROR:
      return {
        ...state,
        impacted_documents: 0,
      }

    case InputMaskActionsEnum.CREATE_MERCHANT_CODE_ATTEMPT: {
      return {
        ...state,
        createOrUpdateMerchantCodeStatus: "loading",
      }
    }

    case InputMaskActionsEnum.CREATE_MERCHANT_CODE_ERROR: {
      return {
        ...state,
        createOrUpdateMerchantCodeStatus: "failure",
      }
    }

    case InputMaskActionsEnum.CREATE_MERCHANT_CODE_RESET: {
      return {
        ...state,
        createOrUpdateMerchantCodeStatus: "idle",
      }
    }

    case InputMaskActionsEnum.CREATE_MERCHANT_CODE_SUCCESS:
      if (!state.edited_full_document) {
        return { ...state }
      }
      return {
        ...state,
        edited_full_document: {
          ...state.edited_full_document,
          merchant_code_id: action.payload.merchant_code_id,
        },
        createOrUpdateMerchantCodeStatus: "success",
      }

    case InputMaskActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_ATTEMPT: {
      return {
        ...state,
        createOrUpdateMerchantCodeStatus: "loading",
      }
    }

    case InputMaskActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_FAILURE: {
      return {
        ...state,
        createOrUpdateMerchantCodeStatus: "failure",
      }
    }

    case InputMaskActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS:
      if (!state.edited_full_document) {
        return { ...state }
      }
      return {
        ...state,
        edited_full_document: {
          ...state.edited_full_document,
          merchant_code_id: action.payload.merchantCodeId,
        },
        createOrUpdateMerchantCodeStatus: "success",
      }

    case InputMaskActionsEnum.GET_INPUT_MASK_ACCOUNTS_ATTEMPT:
      return { ...state, all_company_accounts: null }

    case InputMaskActionsEnum.GET_INPUT_MASK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        autoliquidation_vat_rates: action.payload.autoliquidation_vat_rates,
        all_company_accounts: action.payload.all_company_accounts,
      }

    case InputMaskActionsEnum.GET_INPUT_MASK_ACCOUNTS_ERROR:
      return { ...state, all_company_accounts: null }

    case InputMaskActionsEnum.SELECT_NEW_ACCOUNT: {
      if (!state.all_company_accounts) return { ...state }
      const buyOrSell = action.payload.buyOrsell

      if (buyOrSell === "buy") {
        return {
          ...state,
          accountIdToSelectFromCreation: {
            accountId: action.payload.newAccount.id,
            writinLineUuid: action.payload.writingLineUuid,
          },
          autoliquidation_vat_rates: { ...state.autoliquidation_vat_rates },
          all_company_accounts: {
            sell: { ...state.all_company_accounts.sell },
            buy: {
              ...state.all_company_accounts.buy,
              tax_excluded: [
                ...state.all_company_accounts.buy.tax_excluded,
                action.payload.newAccount,
              ],
            },
          },
        }
      } else {
        return {
          ...state,
          accountIdToSelectFromCreation: {
            accountId: action.payload.newAccount.id,
            writinLineUuid: action.payload.writingLineUuid,
          },
          autoliquidation_vat_rates: { ...state.autoliquidation_vat_rates },
          all_company_accounts: {
            sell: {
              ...state.all_company_accounts.sell,
              tax_excluded: [
                ...state.all_company_accounts.sell.tax_excluded,
                action.payload.newAccount,
              ],
            },
            buy: {
              ...state.all_company_accounts.buy,
            },
          },
        }
      }
    }

    case InputMaskActionsEnum.UPDATE_WRITING_LINES_ATTEMPT: {
      return {
        ...state,
        updateWritinglinesStatus: "loading",
      }
    }

    case InputMaskActionsEnum.UPDATE_WRITING_LINES_SUCCESS: {
      const fullDocuments = copyObject(state.full_documents_to_check)
      const modifiedFullDocuments = fullDocuments.map((fd) => {
        if (fd.fd_id !== action.payload.fullDocumentId) {
          return fd
        }
        if (
          fd.merchant_id !== action.payload.editedFullDocument["merchant_id"]
        ) {
          action.payload.editedFullDocument["merchant_code"] =
            action.payload.merchantCode
          action.payload.editedFullDocument["merchant_code_id"] =
            action.payload.merchantCodeId
        }
        return {
          ...action.payload.editedFullDocument,
          writing_lines: action.payload.editedWritingLines,
        }
      })
      return {
        ...state,
        updateWritinglinesStatus: "success",
        initial_full_document: action.payload.editedFullDocument,
        edited_full_document: action.payload.editedFullDocument,
        initial_writing_lines: action.payload.editedWritingLines,
        edited_writing_lines: action.payload.editedWritingLines,
        full_documents_to_check: modifiedFullDocuments,
        deactivated_full_documents: state.deactivated_full_documents.filter(
          (d) => !d.is_being_reactivated
        ),
      }
    }

    case InputMaskActionsEnum.UPDATE_FULL_DOCUMENT_REVIEW: {
      return {
        ...state,
        edited_full_document: action.payload,
      }
    }

    case InputMaskActionsEnum.SELECT_TAB: {
      const selectedTab = action.payload

      const isCurrentDocumentBeingReactivated =
        state.deactivated_full_documents.some(
          (d) => d.fd_id === state.edited_full_document?.fd_id
        )

      if (
        state.updateWritinglinesStatus === "loading" ||
        state.createOrUpdateMerchantCodeStatus === "loading"
      ) {
        // block navigation while update is pending
        return { ...state }
      }

      if (isCurrentDocumentBeingReactivated) {
        // block navigation while reactivation
        return { ...state }
      }

      if (selectedTab === "deactivated") {
        return {
          ...state,
          selectedTab,
          selectedPage: 0,
          selectedFullDocument: state.deactivated_full_documents[0],
        }
      }
      const firstDocument =
        state.full_documents_to_check && state.full_documents_to_check[0]
          ? copyObject(state.full_documents_to_check[0])
          : null
      return {
        ...state,
        selectedTab,
        selectedPage: 0,
        selectedFullDocument: firstDocument,
        initial_full_document: firstDocument,
        edited_full_document: firstDocument,
        initial_writing_lines: firstDocument?.writing_lines || [],
        edited_writing_lines: firstDocument?.writing_lines || [],
      }
    }

    case InputMaskActionsEnum.FREE_TEXT_FULL_DOC: {
      const selected = state.selectedFullDocument

      if (isAssigned(selected)) {
        selected.free_text_full_doc = action.payload
      }

      return {
        ...state,
        selectedFullDocument: selected,
        edited_full_document: state.edited_full_document
          ? {
              ...state.edited_full_document,
              free_text_full_doc: action.payload,
            }
          : null,
      }
    }

    case InputMaskActionsEnum.SET_SELECTED_PAGE: {
      return {
        ...state,
        selectedPage: action.payload,
      }
    }
    case InputMaskActionsEnum.SET_SELECTED_FULL_DOCUMENT: {
      const isCurrentDocumentBeingReactivated =
        state.deactivated_full_documents.some(
          (d) => d.fd_id === state.edited_full_document?.fd_id
        )

      if (
        state.updateWritinglinesStatus === "loading" ||
        state.createOrUpdateMerchantCodeStatus === "loading"
      ) {
        // block navigation while update is pending
        return { ...state }
      }

      if (isCurrentDocumentBeingReactivated) {
        // block navigation while reactivation
        return { ...state }
      }
      if (!action.payload) {
        return { ...state }
      }

      const isActionFullDocumentDeactivated = isFullDocumentDeactivated(
        action.payload
      )
      if (isActionFullDocumentDeactivated) {
        // in case of deactivated document, only update selectedFullDocument
        return {
          ...state,
          selectedFullDocument: action.payload,
          updateWritinglinesStatus: "idle",
        }
      } else {
        const writing_lines = action.payload.writing_lines

        const without_writing_lines = {
          documents: action.payload.documents,
          fd_id: action.payload.fd_id,
          document_type: action.payload.document_type,
          document_source: action.payload.document_source,
          goods_or_services: action.payload.goods_or_services,
          fd_status: action.payload.fd_status,
          buy_or_sell: action.payload.buy_or_sell,
          journal_code: action.payload.journal_code,
          periodicity_date_start: action.payload.periodicity_date_start,
          periodicity_date_end: action.payload.periodicity_date_end,
          fd_document_date: action.payload.fd_document_date,
          fd_document_due_date: action.payload.fd_document_due_date,
          fd_document_reference: action.payload.fd_document_reference,
          fd_user_file_name: action.payload.fd_user_file_name,
          original_file_name: action.payload.original_file_name,
          legal_entity_id: action.payload.legal_entity_id,
          merchant_code: action.payload.merchant_code,
          merchant_code_id: action.payload.merchant_code_id,
          merchant_name: action.payload.merchant_name,
          merchant_id: action.payload.merchant_id,
          merchant_buy_default_account_id:
            action.payload.merchant_buy_default_account_id,
          merchant_buy_vat_default_account_id:
            action.payload.merchant_buy_vat_default_account_id,
          merchant_sell_default_account_id:
            action.payload.merchant_sell_default_account_id,
          merchant_sell_vat_default_account_id:
            action.payload.merchant_sell_vat_default_account_id,
          previously_deactivated_because: null,
          free_text_full_doc: action.payload.free_text_full_doc,
        }

        return {
          ...state,
          initial_full_document: without_writing_lines,
          edited_full_document: without_writing_lines,
          initial_writing_lines: [...writing_lines],
          edited_writing_lines: [...writing_lines],
          increased_cents: [],
          decreased_cents: [],
          magic_checkbox: {
            vat: null,
            tax_excluded: null,
          },
          impacted_documents: 0,
          selectedFullDocument: action.payload,
          updateWritinglinesStatus: "idle",
        }
      }
    }

    case InputMaskActionsEnum.RESET_REVIEW:
      const newFullDocs = state.full_documents_to_check.map((full_doc) => {
        const initialFullDocument = action.payload.initialFullDocument
        if (
          full_doc.merchant_id === state.edited_full_document?.merchant_id &&
          full_doc.legal_entity_id ===
            state.edited_full_document.legal_entity_id
        ) {
          return {
            ...full_doc,
            merchant_id: initialFullDocument.merchant_id,
            merchant_name: initialFullDocument.merchant_name,
            merchant_code_id: initialFullDocument.merchant_code_id,
            merchant_code: initialFullDocument.merchant_code,
          }
        } else {
          return full_doc
        }
      })

      return {
        ...state,
        edited_full_document: action.payload.initialFullDocument,
        edited_writing_lines: action.payload.initialWritinglines,
        increased_cents: [],
        decreased_cents: [],
        magic_checkbox: {
          vat: null,
          tax_excluded: null,
        },
        impacted_documents: 0,
        full_documents_to_check: newFullDocs,
      }

    case InputMaskActionsEnum.UPDATE_AMOUNT: {
      const existingLines = copyObject(state.edited_writing_lines)

      const modifiedLines = updateAmount({
        newAmount: action.payload.newAmount,
        newCurrencyAmount: action.payload.newCurrencyAmount,
        writingLine: action.payload.writingLine,
        existingLines,
      })

      const newIncreasedCents = state.increased_cents.filter(
        (i) =>
          i.writing_line_uuid !== action.payload.writingLine.writing_line_uuid
      )
      const newDecreasedCents = state.decreased_cents.filter(
        (i) =>
          i.writing_line_uuid !== action.payload.writingLine.writing_line_uuid
      )
      return {
        ...state,
        edited_writing_lines: modifiedLines,
        increased_cents: newIncreasedCents,
        decreased_cents: newDecreasedCents,
      }
    }

    case InputMaskActionsEnum.INCREASE_CENTS: {
      const existingLines = copyObject(state.edited_writing_lines)

      const modifiedLines = increaseCents({
        writingLine: action.payload.writingLine,
        existingLines,
      })

      const hasAlreadyBeenDecreased = state.decreased_cents.some(
        (d) =>
          d.writing_line_uuid === action.payload.writingLine.writing_line_uuid
      )

      if (hasAlreadyBeenDecreased) {
        // if we have decreased VAT previously, reset
        return {
          ...state,
          edited_writing_lines: modifiedLines,
          increased_cents: state.increased_cents.filter(
            (i) =>
              i.writing_line_uuid !==
              action.payload.writingLine.writing_line_uuid
          ),
          decreased_cents: state.decreased_cents.filter(
            (i) =>
              i.writing_line_uuid !==
              action.payload.writingLine.writing_line_uuid
          ),
        }
      }

      // otherwise, only disable + button
      return {
        ...state,
        edited_writing_lines: modifiedLines,
        increased_cents: [
          ...state.increased_cents,
          {
            writing_line_uuid: action.payload.writingLine.writing_line_uuid,
          },
        ],
      }
    }

    case InputMaskActionsEnum.DECREASE_CENTS: {
      const existingLines = copyObject(state.edited_writing_lines)

      const modifiedLines = decreaseCents({
        writingLine: action.payload.writingLine,
        existingLines,
      })

      const hasAlreadyBeenIncreased = state.increased_cents.some(
        (i) =>
          i.writing_line_uuid === action.payload.writingLine.writing_line_uuid
      )

      if (hasAlreadyBeenIncreased) {
        // if we have increased VAT previously, reset
        return {
          ...state,
          edited_writing_lines: modifiedLines,
          decreased_cents: state.decreased_cents.filter(
            (d) =>
              d.writing_line_uuid !==
              action.payload.writingLine.writing_line_uuid
          ),
          increased_cents: state.increased_cents.filter(
            (d) =>
              d.writing_line_uuid !==
              action.payload.writingLine.writing_line_uuid
          ),
        }
      }

      // otherwise, only disable - button
      return {
        ...state,
        edited_writing_lines: modifiedLines,
        decreased_cents: [
          ...state.decreased_cents,
          {
            writing_line_uuid: action.payload.writingLine.writing_line_uuid,
          },
        ],
      }
    }

    case InputMaskActionsEnum.CREATE_LINE: {
      if (!state.initial_full_document || !state.edited_full_document) {
        return { ...state }
      }
      const existingLines = copyObject(state.edited_writing_lines)

      const documentSource: DocumentSource =
        action.payload.selectedOption.includes("world_")
          ? "world"
          : action.payload.selectedOption.includes("eu_")
          ? "eu"
          : "fr"
      const isGoodsOrServices: GoodsOrServices =
        action.payload.selectedOption.includes("goods") ? "goods" : "services"

      const editedFullDocument = {
        ...state.edited_full_document,
        document_source: documentSource,
        goods_or_services: isGoodsOrServices,
      }

      const accountsConfiguration = {
        merchant_buy_default_account_id:
          state.initial_full_document.merchant_buy_default_account_id,
        merchant_buy_vat_default_account_id:
          state.initial_full_document.merchant_buy_vat_default_account_id,
        merchant_sell_default_account_id:
          state.initial_full_document.merchant_sell_default_account_id,
        merchant_sell_vat_default_account_id:
          state.initial_full_document.merchant_sell_vat_default_account_id,
        vat_accounts: state.vat_accounts,
        buy_or_sell:
          state?.edited_full_document?.buy_or_sell ||
          state.initial_full_document.buy_or_sell,
      }

      const autoliquidation_vat_rates = state.autoliquidation_vat_rates

      const { modifiedLines } = createWritingLine({
        existingLines,
        selectedOption: action.payload.selectedOption,
        possibleAccounts: action.payload.possibleAccounts,
        accountsConfiguration,
        documentType: state.edited_full_document.document_type,
        buyThirdPartyAccount: action.payload.buyThirdPartyAccount,
        autoliquidation_vat_rates: autoliquidation_vat_rates,
      })

      return {
        ...state,
        edited_writing_lines: modifiedLines,
        edited_full_document: editedFullDocument,
      }
    }

    case InputMaskActionsEnum.TOGGLE_IS_FUEL_VAT: {
      const existingLines = copyObject(state.edited_writing_lines)

      const modifiedLines = existingLines.map((l) => {
        if (l.writing_line_uuid !== action.payload.writingLineUuid) {
          return l
        }
        return {
          ...l,
          is_fuel_vat_line: !l.is_fuel_vat_line,
        }
      })

      return {
        ...state,
        edited_writing_lines: modifiedLines,
      }
    }

    case InputMaskActionsEnum.UNIT_VALUES: {
      const existingLines = copyObject(state.edited_writing_lines)

      const modifiedLines = existingLines.map((l) => {
        if (
          l.writing_line_uuid === action.payload.writingLineUuid &&
          (l.datum_type === "tax_excluded" ||
            l.datum_type === "tax_excluded_bill_of_exchange")
        ) {
          return {
            ...l,
            unit: { id: action.payload.unit_values.unit.id },
            unit_quantity: action.payload.unit_values.unit_quantity,
            unit_description: action.payload.unit_values.unit_description,
            unit_2: { id: action.payload.unit_values.unit_2.id },
            unit_2_quantity: action.payload.unit_values.unit_2_quantity,
            unit_2_description: action.payload.unit_values.unit_2_description,
          }
        }
        return l
      })

      return {
        ...state,
        edited_writing_lines: modifiedLines,
      }
    }

    case InputMaskActionsEnum.DESTROY_LINE: {
      const existingLines = copyObject(state.edited_writing_lines)

      const modifiedLines = updateTaxIncluded(
        existingLines.filter(
          (l) => l.writing_line_uuid !== action.payload.writingLineUuid
        )
      )
      return {
        ...state,
        edited_writing_lines: modifiedLines,
      }
    }
    case FullDocumentsActionsEnum.FULL_DOCUMENT_UPDATE_SUCCESS: {
      const updatedFullDocument: DeactivatedDocumentInWritingForInputMask[] =
        copyObject(state.deactivated_full_documents)

      updatedFullDocument.forEach((fd) => {
        if (fd.fd_id === action.payload.documentId) {
          fd.deactivated_because = action.payload
            .deactivatedBecause as DeactivatedSettingsKey
        }
      })
      return {
        ...state,
        deactivated_full_documents: updatedFullDocument,
      }
    }

    case InputMaskActionsEnum.CHANGE_MERCHANT: {
      if (!state.edited_full_document) {
        return { ...state }
      }
      const newFullDocs = state.full_documents_to_check.map((full_doc) => {
        if (
          full_doc.merchant_id === state.edited_full_document?.merchant_id &&
          full_doc.legal_entity_id ===
            state.edited_full_document.legal_entity_id
        ) {
          return {
            ...full_doc,
            merchant_id: action.payload.merchantId,
            merchant_name: action.payload.merchantName,
            merchant_code_id: action.payload.merchantCodeId
              ? action.payload.merchantCodeId
              : state.edited_full_document.merchant_code_id,
            merchant_code: action.payload.merchantCode
              ? action.payload.merchantCode
              : state.edited_full_document.merchant_code,
          }
        } else {
          return full_doc
        }
      })
      const newFullDocument = {
        ...state.edited_full_document,
        merchant_id: action.payload.merchantId,
        merchant_name: action.payload.merchantName,
        merchant_code_id: action.payload.merchantCodeId
          ? action.payload.merchantCodeId
          : state.edited_full_document.merchant_code_id,
        merchant_code: action.payload.merchantCode
          ? action.payload.merchantCode
          : state.edited_full_document.merchant_code,
      }
      return {
        ...state,
        edited_full_document: newFullDocument,
        full_documents_to_check: newFullDocs,
      }
    }

    case InputMaskActionsEnum.CHANGE_MERCHANT_CODE: {
      if (!state.edited_full_document) {
        return { ...state }
      }

      const newFullDocs = state.full_documents_to_check.map((full_doc) => {
        if (full_doc.merchant_id === state.edited_full_document?.merchant_id) {
          return {
            ...full_doc,
            merchant_code_id: action.payload.merchantCodeId,
          }
        } else {
          return full_doc
        }
      })

      const newFullDocument = {
        ...state.edited_full_document,
        merchant_code_id: action.payload.merchantCodeId,
      }
      return {
        ...state,
        edited_full_document: newFullDocument,
        full_documents_to_check: newFullDocs,
      }
    }

    case InputMaskActionsEnum.CHANGE_TYPOLOGY: {
      if (!state.edited_full_document) {
        return { ...state }
      }

      const newFullDocument = {
        ...state.edited_full_document,
        document_type: action.payload.documentType,
        buy_or_sell: action.payload.buyOrSell,
      }

      if (action.payload.documentType === "bill_of_exchange") {
        const taxIncludedLine = [
          ...state.edited_writing_lines,
        ].pop() as WritingLinePayload
        const newLineUuid = uuidv4()

        const newWritingLines: WritingLinePayload[] = [
          {
            account:
              state.all_company_accounts?.buy
                ?.tax_excluded_bill_of_exchange[0] || null,
            writing_line_uuid: newLineUuid,
            amount: "0",
            currency_code: taxIncludedLine?.currency_code || "EUR",
            exchange_rate: taxIncludedLine?.exchange_rate || null,
            exchange_rate_date: taxIncludedLine?.exchange_rate_date || null,
            currency_amount: taxIncludedLine?.currency_amount || null,
            datum_type: "tax_excluded_bill_of_exchange",
            direction: "debit",
            vat_rate: "",
            source: "input_mask",
            merchant_code_id: null,
            is_fuel_vat_line: false,
            unit: { id: null },
            unit_quantity: null,
            unit_description: null,
            unit_2: { id: null },
            unit_2_quantity: null,
            unit_2_description: null,
          },
          {
            account:
              state.all_company_accounts?.buy
                ?.tax_included_bill_of_exchange[0] || null,
            writing_line_uuid: newLineUuid,
            amount: "0",
            currency_code: taxIncludedLine?.currency_code || "EUR",
            exchange_rate: taxIncludedLine?.exchange_rate || null,
            exchange_rate_date: taxIncludedLine?.exchange_rate_date || null,
            currency_amount: taxIncludedLine?.currency_amount || null,
            datum_type: "tax_included_bill_of_exchange",
            direction: "credit",
            vat_rate: "",
            source: "input_mask",
            merchant_code_id: null,
            is_fuel_vat_line: false,
            unit: { id: null },
            unit_quantity: null,
            unit_description: null,
            unit_2: { id: null },
            unit_2_quantity: null,
            unit_2_description: null,
          },
        ]
        return {
          ...state,
          edited_full_document: newFullDocument,
          edited_writing_lines: newWritingLines,
        }
      }

      if (action.payload.buyOrSell !== state.edited_full_document.buy_or_sell) {
        const newWritingLines = changeBuyOrSell({
          taxIncludedAccount:
            state.all_company_accounts?.[action.payload.buyOrSell]
              ?.tax_included[0],
          direction: action.payload.buyOrSell === "buy" ? "credit" : "debit",
          currencyCode: state.edited_writing_lines[0].currency_code,
          exchange_rate: state.edited_writing_lines[0].exchange_rate,
          exchange_rate_date: state.edited_writing_lines[0].exchange_rate_date,
          currency_amount: state.edited_writing_lines[0].currency_amount,
        })
        return {
          ...state,
          edited_full_document: newFullDocument,
          edited_writing_lines: newWritingLines,
        }
      } else if (
        action.payload.documentType !== state.edited_full_document.document_type
      ) {
        const newWritingLines = changeDocumentType({
          currentWritingLines: state.edited_writing_lines,
        })
        return {
          ...state,
          edited_full_document: newFullDocument,
          edited_writing_lines: newWritingLines,
        }
      }
      return {
        ...state,
        edited_full_document: newFullDocument,
      }
    }

    case InputMaskActionsEnum.CHANGE_BILL_OF_EXCHANGE_MERCHANT: {
      const modifiedLines = copyObject(state.edited_writing_lines)

      modifiedLines.forEach((l, index) => {
        if (
          l.writing_line_uuid ===
            action.payload.writingLine.writing_line_uuid &&
          l.datum_type === action.payload.writingLine.datum_type
        ) {
          modifiedLines[index].merchant_code_id =
            action.payload.newMerchantCodeId
        }
      })

      return { ...state, edited_writing_lines: modifiedLines }
    }

    case InputMaskActionsEnum.CHANGE_ACCOUNT: {
      const modifiedLines = copyObject(state.edited_writing_lines)

      modifiedLines.forEach((l, index) => {
        if (
          l.writing_line_uuid ===
            action.payload.writingLine.writing_line_uuid &&
          l.datum_type === action.payload.writingLine.datum_type
        ) {
          modifiedLines[index].account = action.payload.newAccount
        }
      })

      if (
        state.initial_writing_lines.some(
          (l) =>
            l.writing_line_uuid === action.payload.writingLine.writing_line_uuid
        ) &&
        isMagicCheckBoxDatumType(action.payload.writingLine.datum_type) &&
        !state.magic_checkbox[action.payload.writingLine.datum_type]
          ?.writing_line_uuid
      ) {
        return {
          ...state,
          edited_writing_lines: modifiedLines,
          magic_checkbox: {
            ...state.magic_checkbox,
            [action.payload.writingLine.datum_type]: {
              writing_line_uuid: action.payload.writingLine.writing_line_uuid,
            },
          },
        }
      }

      return {
        ...state,
        edited_writing_lines: modifiedLines,
      }
    }

    case InputMaskActionsEnum.TOGGLE_BLOCKED_BY_ALREADY_TAKEN_MERCHANT_CODE: {
      return {
        ...state,
        blockedByAlreadyTakenMerchantCode:
          action.payload.blockedByAlreadyTakenMerchantCode,
      }
    }

    case InputMaskActionsEnum.FOCUS_LINE: {
      return {
        ...state,
      }
    }

    case InputMaskActionsEnum.EXPAND_ALL_INVOICES: {
      return {
        ...state,
        expandInvoicesList: "expanded",
      }
    }
    case InputMaskActionsEnum.MINIMIZE_ALL_INVOICES: {
      return {
        ...state,
        expandInvoicesList: "minimized",
      }
    }

    case InputMaskActionsEnum.STORE_CONTEXT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case InputMaskActionsEnum.MERCHANT_INSTRUCTION_SUCCESS: {
      return {
        ...state,
        magic_checkbox: {
          vat: null,
          tax_excluded: null,
        },
      }
    }

    case InputMaskActionsEnum.MERCHANT_INSTRUCTION_ERROR: {
      return {
        ...state,
        magic_checkbox: {
          vat: null,
          tax_excluded: null,
        },
      }
    }

    case InputMaskActionsEnum.SET_MERCHANT_INSTRUCTION: {
      return {
        ...state,
        magic_checkbox: {
          ...state.magic_checkbox,
          [action.payload.datum_type]: {
            ...state.magic_checkbox[action.payload.datum_type],
            merchant_instruction: action.payload.merchant_instruction,
          },
        },
      }
    }

    case InputMaskActionsEnum.DEACTIVATE_FULL_DOCUMENT_SUCCESS:
      return { ...state, deactivate_status: "success" }

    case InputMaskActionsEnum.DEACTIVATE_FULL_DOCUMENT_RESET:
      return { ...state, deactivate_status: "idle" }

    case InputMaskActionsEnum.GET_IDENTIFICATION_NUMBERS_ATTEMPT:
      return {
        ...state,
        identificationNumbers: [],
        identificationNumbersLoadingStatus: "loading",
      }

    case InputMaskActionsEnum.GET_IDENTIFICATION_NUMBERS_SUCCESS:
      return {
        ...state,
        identificationNumbers: action.payload,
        identificationNumbersLoadingStatus: "finished",
      }

    case InputMaskActionsEnum.GET_IDENTIFICATION_NUMBERS_ERROR:
      return {
        ...state,
        identificationNumbers: [],
        identificationNumbersLoadingStatus: "finished",
      }

    case InputMaskActionsEnum.CREATE_MERCHANT_ATTEMPT:
      return {
        ...state,
        createMerchantStatus: "loading",
      }

    case InputMaskActionsEnum.CREATE_MERCHANT_RESET:
      return {
        ...state,
        createMerchantStatus: "idle",
      }

    case InputMaskActionsEnum.CREATE_MERCHANT_SUCCESS: {
      if (!state.edited_full_document) {
        return { ...state }
      }

      return {
        ...state,
        createMerchantStatus: "success",
        edited_full_document: {
          ...state.edited_full_document,
          merchant_name: action.payload.name,
          merchant_id: action.payload.id,
          merchant_code_id: action.payload.code_id || 0,
          legal_entity_id: action.payload.legal_entity_id || 0,
        },
      }
    }

    case InputMaskActionsEnum.REACTIVATE_DOCUMENT:
      const deactivatedDocument = state.deactivated_full_documents.find(
        (d) => d.fd_id === action.payload.fd_id
      )
      if (!deactivatedDocument) {
        return { ...state }
      }
      const full_document: FullDocumentOnly = {
        documents: [...deactivatedDocument.documents],
        fd_id: deactivatedDocument?.fd_id,
        document_type: "invoice",
        buy_or_sell: "buy",
        fd_status: "",
        journal_code: "",
        periodicity_date_end: null,
        periodicity_date_start: null,
        fd_document_date: "",
        fd_document_due_date: null,
        fd_document_reference: null,
        legal_entity_id: 0,
        fd_user_file_name: "",
        original_file_name: deactivatedDocument?.original_batch_name || "",
        merchant_code: "",
        merchant_code_id: 0,
        merchant_name: "",
        merchant_id: 0,
        merchant_buy_default_account_id: null,
        merchant_buy_vat_default_account_id: null,
        merchant_sell_default_account_id: null,
        merchant_sell_vat_default_account_id: null,
        goods_or_services: null,
        document_source: null,
        previously_deactivated_because: deactivatedDocument.deactivated_because,
        free_text_full_doc: null,
      }

      const writing_lines = generateEmptyTaxIncluded()
      return {
        ...state,
        selectedTab: "processed",
        deactivated_full_documents: state.deactivated_full_documents.map(
          (d) => {
            if (d.fd_id === full_document.fd_id) {
              return { ...d, is_being_reactivated: true }
            }
            return d
          }
        ),
        full_documents_to_check: [
          ...state.full_documents_to_check,
          {
            ...full_document,
            writing_lines: writing_lines,
          },
        ],
        initial_full_document: full_document,
        edited_full_document: full_document,
        initial_writing_lines: writing_lines,
        edited_writing_lines: writing_lines,
        increased_cents: [],
        decreased_cents: [],
        magic_checkbox: {
          vat: null,
          tax_excluded: null,
        },
        impacted_documents: 0,
        selectedPage: 0,
        selectedFullDocument: {
          ...full_document,
          writing_lines: [],
        },
      }
    case InputMaskActionsEnum.CANCEL_DOCUMENT_REACTIVATION:
      return {
        ...state,
        deactivated_full_documents: state.deactivated_full_documents.map(
          (d) => {
            return { ...d, is_being_reactivated: false }
          }
        ),
        full_documents_to_check: state.full_documents_to_check.filter(
          (fd) => fd.fd_id !== action.payload.fd_id
        ),
        selectedTab: "deactivated",
        initial_full_document: null,
        edited_full_document: null,
        initial_writing_lines: [],
        edited_writing_lines: [],
        selectedFullDocument: state.deactivated_full_documents.find(
          (d) => d.fd_id === action.payload.fd_id
        ) as DeactivatedDocumentInWritingForInputMask,
      }

    case InputMaskActionsEnum.RESET_ACCOUNT_TO_SELECT:
      return {
        ...state,
        accountIdToSelectFromCreation: null,
      }

    default:
      return { ...state }
  }
}

export const isAssigned = (
  selectedFullDocument:
    | FullDocumentForInputMask
    | DeactivatedDocumentInWritingForInputMask
    | null
): selectedFullDocument is FullDocumentForInputMask => {
  return (
    (selectedFullDocument as FullDocumentForInputMask).fd_status === "assigned"
  )
}

interface InputMaskWritingResponse {
  full_documents_to_check: FullDocumentForInputMask[]
  deactivated_full_documents: DeactivatedDocumentInWritingForInputMask[]
  vat_accounts: VatAccountsWithRate[]
  fuel_vat_percentage: string
  can_add_free_text_full_doc: boolean
}

export const getInputMaskWritingThunk =
  ({ fiscalYearId, companyId }: { fiscalYearId: number; companyId: number }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getInputMaskWritingAttemptAction())
    return axios
      .get<InputMaskWritingResponse>(
        "/full_documents/input_mask_from_writing",
        {
          params: {
            fiscal_year_id: fiscalYearId,
            company_id: companyId,
          },
        }
      )
      .then(({ data }) => {
        dispatch(getInputMaskWritingSuccessAction(data))
        dispatch(storeContext({ fiscalYearId, companyId, merchantId: null }))
      })
      .catch((error: AxiosError) => {
        getInputMaskWritingErrorAction(error)
      })
  }

type InputMaskMerchantResponse = {
  full_documents_to_check: FullDocumentForInputMask[]
  vat_accounts: VatAccountsWithRate[]
  fuel_vat_percentage: string
}

export const getInputMaskMerchantThunk =
  ({
    fiscalYearId,
    merchantId,
    companyId,
  }: {
    fiscalYearId: number
    merchantId: number
    companyId: number
  }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getInputMaskMerchantAttemptAction())
    return axios
      .get<InputMaskMerchantResponse>(
        "/full_documents/input_mask_from_merchant",
        {
          params: {
            fiscal_year_id: fiscalYearId,
            merchant_id: merchantId,
            company_id: companyId,
          },
        }
      )
      .then(({ data }) => {
        dispatch(getInputMaskMerchantSuccessAction(data))
        dispatch(storeContext({ fiscalYearId, companyId, merchantId }))
      })
      .catch((error: AxiosError) => {
        getInputMaskMerchantErrorAction(error)
      })
  }

export interface AllAccounts {
  tax_excluded: Account[]
  vat: Account[]
  tax_included: Account[]
  eu_vat: Account[]
  eu_reverse_vat: Account[]
  eu_goods_vat: Account[]
  eu_goods_reverse_vat: Account[]
  world_vat: Account[]
  world_reverse_vat: Account[]
  world_goods_vat: Account[]
  world_goods_reverse_vat: Account[]
  construction_vat: Account[]
  construction_reverse_vat: Account[]
  deposit: Account[]
  reverse_deposit: Account[]
  custom: Account[]
  tax_excluded_bill_of_exchange: Account[]
  tax_included_bill_of_exchange: Account[]
}

export const defaultAccounts: AllAccounts = {
  tax_excluded: [],
  vat: [],
  tax_included: [],
  eu_vat: [],
  eu_reverse_vat: [],
  eu_goods_vat: [],
  eu_goods_reverse_vat: [],
  world_vat: [],
  world_reverse_vat: [],
  world_goods_vat: [],
  world_goods_reverse_vat: [],
  construction_vat: [],
  construction_reverse_vat: [],
  deposit: [],
  reverse_deposit: [],
  custom: [],
  tax_excluded_bill_of_exchange: [],
  tax_included_bill_of_exchange: [],
}

export interface AutoliquidationVat {
  eu_vat: VatRate
  eu_goods_vat: VatRate
  world_vat: VatRate
  world_goods_vat: VatRate
  construction_vat: VatRate
}

interface InputMaskAccountsPayload {
  autoliquidation_vat_rates: AutoliquidationVat
  all_company_accounts: AllCompanyAccounts
}

interface AllCompanyAccounts {
  buy: AllAccounts
  sell: AllAccounts
}

export const getInputMaskAccountsThunk =
  ({ companyId }: { companyId: number }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getInputMaskAccountsAttemptAction())
    return axios
      .get<InputMaskAccountsPayload>("/accounts/get_input_mask_accounts", {
        params: {
          company_id: companyId,
        },
      })
      .then(({ data }) => {
        const payload = {
          autoliquidation_vat_rates: data.autoliquidation_vat_rates,
          all_company_accounts: {
            buy: data.all_company_accounts.buy,
            sell: data.all_company_accounts.sell,
          },
        }
        dispatch(getInputMaskAccountsSuccessAction(payload))
      })
      .catch((error: AxiosError) => {
        dispatch(getInputMaskAccountsErrorAction(error))
      })
  }

export const updateWritingLinesThunk =
  ({
    merchantCodeId,
    merchantCode,
    fullDocumentId,
    initialWritinglines,
    editedWritingLines,
    initialFullDocument,
    editedFullDocument,
    writingLinesHaveChanged,
  }: {
    merchantCodeId: number
    merchantCode: string
    fullDocumentId: number
    initialWritinglines: WritingLinePayload[]
    editedWritingLines: WritingLinePayload[]
    initialFullDocument: FullDocumentOnly
    editedFullDocument: FullDocumentOnly
    writingLinesHaveChanged: boolean
  }) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    dispatch(updateWritingLinesAttemptAction())

    const formatedInitialWritingLines: WritingLine[] = initialWritinglines.map(
      (l) => ({
        ...l,
        vat_rate: Number(l.vat_rate) as VatRate,
      })
    )
    const formatedEditedWritingLines: WritingLine[] = editedWritingLines.map(
      (l) => ({
        ...l,
        vat_rate: Number(l.vat_rate) as VatRate,
      })
    )

    return axios
      .post<unknown>("/full_documents/input_mask_recreate_writing_lines", {
        merchant_code_id: merchantCodeId,
        merchant_code: merchantCode,
        full_document_id: fullDocumentId,
        initial_writing_lines: formatedInitialWritingLines,
        edited_writing_lines: formatedEditedWritingLines,
        initial_full_document: initialFullDocument,
        edited_full_document: editedFullDocument,
        writing_lines_have_changed: writingLinesHaveChanged,
      })
      .then(() => {
        dispatch(
          updateWritingLinesSuccessAction({
            editedWritingLines,
            editedFullDocument,
            fullDocumentId,
            merchantCodeId,
            merchantCode,
          })
        )

        if (
          getState().inputMask.edited_full_document?.merchant_code_id !==
            merchantCodeId &&
          getState().inputMask.companyId
        ) {
          dispatch(
            GetAllMerchantsOfCompanyForCodes(
              getState().inputMask.companyId || 0
            )
          )
        }

        const taxExcludedMerchantInstruction =
          getState().inputMask.magic_checkbox.tax_excluded?.merchant_instruction
        if (taxExcludedMerchantInstruction) {
          dispatch(giveMerchantInstructionThunk(taxExcludedMerchantInstruction))
        }

        const vatMerchantInstruction =
          getState().inputMask.magic_checkbox.vat?.merchant_instruction
        if (vatMerchantInstruction) {
          dispatch(giveMerchantInstructionThunk(vatMerchantInstruction))
        }
      })
      .catch((error: AxiosError<{ error: string }>) => {
        if (
          error.response?.data?.error.includes(
            "Ce merchant est paramétré sans TVA"
          )
        ) {
          dispatch(
            updateWritingLinesErrorAction({
              titleKey: `input-mask.toaster.error-vat-${initialFullDocument.buy_or_sell}.title`,
              bodyKey: `input-mask.toaster.error-vat-${initialFullDocument.buy_or_sell}.body`,
            })
          )
        } else if (
          error.response?.data.error.includes(
            "Document reference must be present for sell"
          )
        ) {
          dispatch(
            updateWritingLinesErrorAction({
              titleKey: `input-mask.toaster.error-sell-reference.title`,
              bodyKey: `input-mask.toaster.error-sell-reference.body`,
            })
          )
        } else if (
          error.response?.data.error.includes(
            "Cette référence existe déjà en vente"
          )
        ) {
          dispatch(
            updateWritingLinesErrorAction({
              titleKey: `input-mask.toaster.error-sell-duplicate-reference.title`,
              bodyKey: `input-mask.toaster.error-sell-duplicate-reference.body`,
            })
          )
        } else if (
          error.response?.data.error.includes(
            "Impossible de sauvegarder : Ce code fournisseur"
          )
        ) {
          const message = error.response.data.error
            .split("sauvegarder : ")[1]
            .split(" :si")[0]
          dispatch(
            updateWritingLinesErrorAction({
              titleKey: `input-mask.toaster.error-code-already-used.title`,
              bodyKey: message,
            })
          )
        } else if (
          error.response?.data.error.includes(
            "DocumentAssignManager::BadReverseDeposit"
          )
        ) {
          dispatch(
            updateWritingLinesErrorAction({
              titleKey: `input-mask.toaster.error-bad-reverse-deposit.title`,
              bodyKey: `input-mask.toaster.error-bad-reverse-deposit.body`,
            })
          )
        } else {
          dispatch(
            updateWritingLinesErrorAction({
              titleKey: "input-mask.toaster.error.title",
              bodyKey: "input-mask.toaster.error.body",
            })
          )
        }
      })
  }

interface HistoryLine {
  created_at: string
  email: string
  full_document_id: number
  initial_full_document: FullDocumentOnly | null
  edited_full_document: FullDocumentOnly | null
  initial_writing_lines: WritingLine[]
  edited_writing_lines: WritingLine[]
}

export const getHistoryThunk =
  ({ fullDocumentId }: { fullDocumentId: number }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getHistoryAttemptAction())
    return axios
      .get<HistoryLine[]>("/full_documents/input_mask_history", {
        params: { full_document_id: fullDocumentId },
      })
      .then(({ data }) => {
        const history_modifications = data
          .map((historyLine) => {
            return [
              ...getWritingLineChanges({
                initial_writing_lines: historyLine.initial_writing_lines,
                edited_writing_lines: historyLine.edited_writing_lines,
                created_at: historyLine.created_at,
                email: historyLine.email,
              }),
              ...getFullDocumentModification({
                initial_full_document: historyLine.initial_full_document,
                edited_full_document: historyLine.edited_full_document,
                initial_writing_lines: historyLine.initial_writing_lines,
                edited_writing_lines: historyLine.edited_writing_lines,
                created_at: historyLine.created_at,
                email: historyLine.email,
              }),
            ]
          })
          .flat()
        dispatch(
          getHistorySuccessAction({
            history_modifications,
          })
        )
      })
      .catch((error: AxiosError) => {
        dispatch(getHistoryErrorAction(error))
      })
  }

export interface GiveMerchantInstructionPayload {
  instruction: InstructionType
  merchantId: number
  payload: MerchantInstructionPayload
  buyOrSell: buyOrSell
}

export const giveMerchantInstructionThunk =
  ({
    instruction,
    merchantId,
    payload,
    buyOrSell,
  }: GiveMerchantInstructionPayload) =>
  (dispatch: Dispatch<AnyAction>) =>
    axios
      .put("/merchants/give_merchant_instruction", {
        id: merchantId,
        merchant: { ...payload, instruction_type: instruction },
      })
      .then(() => {
        dispatch(merchantInstructionSuccess({ buyOrSell }))
      })
      .catch((error) => {
        if (error.request && error.response.data && error.response.data.error) {
          const message = error.response.data.error
          if (message.includes("est défini par défaut")) {
            dispatch(BanLockedAccountFailure())
            return
          }
          if (message.includes("est interdit")) {
            dispatch(LockBannedAccountFailure())
            return
          }
          if (message.includes("Deactivated account")) {
            dispatch(DeactivatedAccountFailure())
            return
          }
          if (message.includes("Spaces in the beginning or the end")) {
            dispatch(EditMerchantNameFailure("space"))
            return
          }
          if (message.includes("character-unauthorized")) {
            return dispatch(EditMerchantNameFailure("character"))
          }

          dispatch(merchantInstructionError({ buyOrSell }))
        }
      })

export const getInputMaskMerchantImpactedDocuments =
  ({
    companyId,
    legalEntityId,
  }: {
    companyId: number
    legalEntityId: number
  }) =>
  (dispatch: Dispatch<AnyAction>) => {
    if (!legalEntityId) {
      return new Promise(() => {})
    }
    dispatch(getMerchantImpactedDocumentsAttemptAction())
    return axios
      .get<{ impacted_documents: number }>(
        "/full_documents/input_mask_merchant_impacted_documents",
        {
          params: {
            company_id: companyId,
            legal_entity_id: legalEntityId,
          },
        }
      )
      .then(({ data }) => {
        dispatch(getMerchantImpactedDocumentsSuccessAction(data))
      })
      .catch((error: AxiosError) => {
        dispatch(getMerchantImpactedDocumentsErrorAction(error))
      })
  }

export const updateCentralizeStatusFromInputMask =
  (merchantCodeId: number, centralize: boolean, companyId: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(UpdateMerchantCodeCentralizeAttempt())
    return axios
      .put("/merchant_codes/update_centralize_status", {
        id: merchantCodeId,
        centralize: centralize,
      })
      .then(() => {
        dispatch(
          UpdateMerchantCodeCentralizeSuccess({
            merchantCodeId,
          })
        )
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
      .catch((error: AxiosError) => {
        if (
          JSON.stringify(error.response?.data).includes(
            "cannot centralize because company has disabled centralized codes"
          )
        ) {
          dispatch(GetCompanyThunk(companyId))
        }
        dispatch(UpdateMerchantCodeCentralizeFailure(error))
      })
  }

export const createMerchantCodeThunk =
  (
    companyId: number,
    code: string,
    centralize: boolean,
    merchantIds: number[],
    defaultBuyCode: boolean,
    defaultSellCode: boolean
  ) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    dispatch(createMerchantCodeAttemptAction())
    return axios
      .put<{ merchant_code_id: number }>("/merchant_codes/create_new_code", {
        company_id: companyId,
        code: code,
        centralize: centralize,
        merchant_ids: [], // do not attach merchant code to legal_entity yet, only create
        default_buy_code: defaultBuyCode,
        default_sell_code: defaultSellCode,
      })
      .then((response) => {
        const merchant_code_id = response.data.merchant_code_id
        dispatch(
          addMerchantCode({
            id: merchant_code_id,
            code: code,
            centralize: centralize,
            auxiliary: false,
            default_buy_code: defaultBuyCode,
            default_sell_code: defaultSellCode,
            code_instructions: [],
            merchants: [],
          })
        )
        const state = getState().inputMask
        if (state.edited_full_document) {
          dispatch(
            getInputMaskMerchantImpactedDocuments({
              companyId,
              legalEntityId: state.edited_full_document.legal_entity_id,
            })
          )
        }

        dispatch(
          createMerchantCodeSuccessAction({
            merchant_code_id,
          })
        )
      })
      .catch((e) => {
        dispatch(createMerchantCodeErrorAction(displayErrorMerchantCodes(e)))
      })
  }

export const inputMaskDeactivateFullDocumentThunk =
  ({
    fullDocumentId,
    deactivatedBecause,
  }: {
    fullDocumentId: number
    deactivatedBecause: string
  }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(deactivateFullDocumentAttemptAction())
    return axios
      .put("/full_documents/input_mask_deactivate", {
        full_document_id: fullDocumentId,
        deactivated_because: deactivatedBecause,
      })
      .then(() => {
        dispatch(deactivateFullDocumentSuccessAction({ fullDocumentId }))
      })
      .catch((error: AxiosError) => {
        dispatch(deactivateFullDocumentErrorAction(error))
      })
  }

interface FullDocumentIdentificationNumber {
  legal_entity_name: string
  identification_number: string
  merchant_id: number | null
}

export const getInputMaskIdentificationNumbersThunk =
  ({ fullDocumentId }: { fullDocumentId: number }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getIdentificationNumbersAttemptAction())
    return axios
      .get<FullDocumentIdentificationNumber[]>(
        `/full_documents/${fullDocumentId}/input_mask_get_identification_numbers`
      )
      .then(({ data }) => {
        dispatch(getIdentificationNumbersSuccessAction(data))
      })
      .catch((error: AxiosError) => {
        dispatch(getIdentificationNumbersErrorAction(error))
      })
  }

interface CreateMerchantResponse extends MerchantsOfCompany {
  legal_entity_id: number
}
export const createMerchantFromInputMaskThunk =
  ({
    selectedMerchant,
    merchantToCreate,
    merchantCodeToCreate,
    selectedMerchantCode,
    fullDocumentId,
    selectedSiren,
    merchantCodeToCentralize,
  }: {
    selectedMerchant: MerchantsOfCompany | null
    merchantToCreate: string | null
    selectedMerchantCode: MerchantCode | null
    merchantCodeToCreate: string | null
    fullDocumentId: number
    selectedSiren: string | null
    merchantCodeToCentralize: MerchantCode | null
  }) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    dispatch(createMerchantAttemptAction())
    const createMerchantCodeCall = merchantCodeToCentralize
      ? axios
          .put("/merchant_codes/update_centralize_status", {
            id: merchantCodeToCentralize.id,
            centralize: true,
          })
          .then(() => {
            return axios.put<CreateMerchantResponse>(
              `/full_documents/${fullDocumentId}/input_mask_create_merchant`,
              {
                selectedMerchant,
                merchantToCreate,
                selectedMerchantCode: {
                  ...merchantCodeToCentralize,
                  centralize: true,
                },
                selectedSiren,
                merchantCodeToCreate: null,
              }
            )
          })
      : axios.put<CreateMerchantResponse>(
          `/full_documents/${fullDocumentId}/input_mask_create_merchant`,
          {
            selectedMerchant,
            merchantToCreate,
            selectedMerchantCode,
            selectedSiren,
            merchantCodeToCreate,
          }
        )

    return createMerchantCodeCall
      .then(({ data }) => {
        dispatch(createMerchantSuccessAction(data))
        dispatch(CreateOrUpdateMerchant(data))
        dispatch(
          CreateMerchantCodeFromInputMask({
            id: data.code_id || 0,
            code: data.code || "",
            centralize: false,
            auxiliary: false,
            merchants: [
              {
                merchant_id: data.id,
                merchant_name: data.name,
                code_id: data.code_id || 0,
              },
            ],
            code_instructions: [],
            default_buy_code: false,
            default_sell_code: false,
          })
        )
        const state = getState().inputMask

        if (state.initial_full_document && state.companyId) {
          dispatch(
            getInputMaskMerchantImpactedDocuments({
              companyId: state.companyId,
              legalEntityId: state.initial_full_document.legal_entity_id,
            })
          )
        }
      })
      .catch((error: AxiosError<{ error: string }>) => {
        dispatch(createMerchantErrorAction(error))
      })
  }
